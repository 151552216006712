import React, { createContext, useContext, useEffect, useState } from "react";
import instance from "../services/instance";
import axios from "axios";
import { useWhitelabel } from "./whitelabel.context";
import { LoginBetfive, VerificaToken } from "../services/betfive";

const AuthContext = createContext({})
AuthContext.displayName = 'AuthContext'

const AuthProvider = ({ children }) => {
    const [authStatus, setAuthStatus] = useState(false)
    const [statusWizard, setStatusWizard] = useState()
    const {clienteId} = useWhitelabel()

    const [newToken, setNewToken] = useState()
    

    useEffect(()=>{
        VerifAuth()
    }, [])

    
    const VerifAuth = async() => {
        if(localStorage.getItem('tokenPlaygreen') && localStorage.getItem('MobToken')){
            setAuthStatus(true)
        } else {
            setAuthStatus(false)
        }
    }

  
    const Login = async (userData) => {
        try {
            const response = await instance.post(`/Inicio/Login`, userData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            
            sessionStorage.setItem(`@Token:BetBuilder`, response.data.token)
            localStorage.setItem(`@Email:BetBuilder`, response.data.email)
            localStorage.setItem(`@Nome:BetBuilder`, response.data.nome)
            localStorage.setItem(`@Telefone:BetBuilder`, response.data.telefone)

            // await LoginBetfive({
            //     username: userData.email,
            //     password: userData.senha
            // })
            
            
            

            
            return response
        } catch(error){
            //console.log('Erro no AuthContext: ', error)
            throw error
        }
    }

    

    const UpdateWizardBool = async () => {
        try {
            const response = await instance.post(`/Perfil/UpdateWizard?email=${localStorage.getItem('@Email:BetBuilder')}&ClienteId=${localStorage.getItem('@ClientId:BetBuilder')}`)
            //console.log(response, " : updateWizardBool")
            return response
        } catch (err) {
            throw err
        }
    }

    const Register = async (userData) => {
        try {
            const response = await instance.post(`/Inicio/RegistrarUsuario`, userData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            return response
        } catch (error) {
            //console.log(error.response.data.message)
            //console.log('Erro no AuthContext: ', error)
            throw error
        }
    }

    const GetInfoPerfil = async (email, clienteId) => {
        try {
            const response = await instance.get(`/Perfil/GetInfoUsuario?email=${email}&clienteId=${clienteId}`)

            return response
        } catch(err) {
            //console.log('Erro ao carregar as informações de usuario no useAuth: ', err)
            throw err
        }
    }

    const UpdateCodigoAfiliado = async (codigo) => {
        try {
            const response = await instance.post(`/Inicio/AtualizarCodigoAfiliado`, {
                codigo: codigo,
                clienteId: localStorage.getItem('@ClientId:BetBuilder'),
                email: localStorage.getItem('@Email:BetBuilder')
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            return response
        } catch (err) {
            //console.log('Erro ao atualizar o codigo do afiliado: ', err)
            throw err.response.data
        }
    }

    const AtualizarToken = async (token, username, clienteId) => {
        try {
            const response = await instance.post(`/Inicio/AtualizarTokenBetfive?username=${username}&clienteId=${clienteId}&token=${token}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            return response
        } catch (err) {
            //console.log('Erro ao atualizar o codigo do afiliado: ', err)
            throw err.response.data
        }
    }


    return (
        <AuthContext.Provider value={{     
                authStatus,
                Login,
                statusWizard,
                setStatusWizard,
                Register,
                GetInfoPerfil,
                setAuthStatus,
                UpdateWizardBool,
                UpdateCodigoAfiliado,
                AtualizarToken,
                VerifAuth,
                setNewToken,
                newToken
                
        }}>
            {children}
        </AuthContext.Provider>
    )
}


export const useAuth = () => {
    const context = useContext(AuthContext)

    if(!context) {
        throw new Error("Erro ao usar o AuthProvider")
    }

    return context;
}

export {AuthContext, AuthProvider}