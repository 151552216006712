import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Certifique-se de importar os estilos

const SlideShow = ({ listaImagens }) => {
  return (
    <Carousel
        autoPlay={true}
        interval={3000}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        emulateTouch={true}
        stopOnHover={false} // Adicione esta linha para desativar a parada ao passar o mouse
        swipeable={true} 
    >
      {listaImagens.map((img, index) => (
        <div key={index} style={{ cursor: 'pointer' }} onClick={(e) => {
          window.location.href = './Games'
        }}>
          <img src={img} alt={`Slide ${index}`} onClick={(e) => {
            window.location.href = '/Games'
          }} />
        </div>
      ))}
    </Carousel>
  );
};

export default SlideShow;
