export function combinarListas(listaJogos, listaStatus) {
    if(!listaJogos || !listaStatus || listaJogos.length === 0 || listaStatus.length === 0) return []

    if(!listaStatus) return listaJogos
    console.log("ListaJogos: ", listaJogos)
    console.log("ListaStatus: ", listaStatus)
    for(let i = 0; i < listaJogos.length; i++) {
        for(let j = 0; j < listaStatus.length; j++) {
            
            if(listaJogos[i].roletaId === listaStatus[j].roletaid) {
                listaJogos[i].StatusResultado = listaStatus[j].StatusResultado
                listaJogos[i].jogadaId = listaStatus[j].jogadaId
                listaJogos[i].texto = listaStatus[j].texto
                listaJogos[i].ativo = listaStatus[j].ativo
                if(listaJogos[i].integracao == 1){
                    listaJogos[i].fazerRequest = listaStatus[j].fazerRequest
                }
                
                break
            } else {
                listaJogos[i].StatusResultado = 0
            }
            
        }
    }
    return listaJogos
}

