import styled from "styled-components";

export const Container = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    flex-direction: flex-end;
    align-items: center;
    padding-top: var(--Header-height);
    
`;

export const DivPai = styled.div`
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 0px;

    @media screen and (max-width: 1024px){
        width: 100%;
        margin-left: 0;
        padding: 10px;
    }
`;

export const DivIframe = styled.div`
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;

    @media screen and (max-width: 1024px){
        width: 100%;
        margin-left: 0;
        padding: 10px;
    }
`;

export const ContainerInfo = styled.span`
    border: 1px solid var(--Toggle-color);
    border-radius: 8px;
    padding: 5px 10px;
    text-align: left;
    color: var(--Primary-text);
    /* min-width: 240px; */
    max-width: 260px;
    height: 35px;
    display: flex;
    align-items: center;
    

    @media screen and (max-width: 770px){
        width: 100%;
        max-width: 100000px;
    }
`;

export const DivContainerInfo = styled.div`
        
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        label{
            color: var(--Primary-text);
            font-weight: 600;
            font-size: 14px;
        }

        @media screen and (max-width: 770px){
            width: 100%;
        }
`;

export const DivInfosEntrada = styled.div`
    
    padding: 0 10px; 
    display: flex; 
    flex-direction: row;
    
    gap: 10px; 
    justify-content: flex-start; 
    align-items: flex-start; 
    
    
    @media screen and (min-width: 771px){
        flex-wrap: wrap-reverse; 
        height: 140px;
        width: 100%;
    }

    @media screen and (max-width: 770px){
            
            flex-direction: column;
            justify-content: center; 
            align-items: center; 
            padding: 10px 0;
            width: 100%;
            
        }

    
`;