import React, {useState, useEffect} from "react";
import SignedRoutes from "./signed.routes";
import NotSignedRoutes from "./notSigned.routes";
import { useWhitelabel } from "../context/whitelabel.context";
import { useAuth } from "../context/auth.context";

export default function Router(){
    const {coresRoot, dominio} = useWhitelabel();
    const {authStatus, VerifAuth} = useAuth()
    
    return (
        <>
            <style>
                {coresRoot}
            </style>

            {!authStatus ? <NotSignedRoutes/> : <SignedRoutes/>}
        </>
    )
}