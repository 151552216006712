import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useControles } from "../../../context/controles.context";
import { Deposit, Withdraw } from "../../../services/betfive";
import { PrimaryButton, PrimaryTextInput } from "../../../globalComponents";
import LoadingMain from "../../Loadings/LoadingMain";
import { useWhitelabel } from "../../../context/whitelabel.context";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '15px 5px',
    maxWidth: '600px',
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'var(--Secondary-Background)',
    borderRadius: '8px',
    gap: 2,
    
  };

const ModalSaque = () => {
    const {open, setOpen, tituloModal, urlModal, openSaque, setopenSaque} = useControles();

    const {urlSacar} = useWhitelabel()

    const handleOpen = () => setopenSaque(true);
    const handleClose = () => setopenSaque(false);

    const [objSaque, setobjSaque] = useState()
    const [valor, setvalor] = useState()
    const [password, setpassword] = useState()
    const [cpf, setcpf] = useState()
    const [errorSaque, setErrorSaque] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [sucess, setSucess] = useState(false)

    const fetchSaque = async (value, password, cpf) => {
        setIsLoading(true)
        await Withdraw(value, password, cpf).then((res) =>{
            setIsLoading(false)
            setobjSaque(res.data)
            console.log(res)
            setSucess(res.data)
            setErrorSaque(false)
        }).catch(err => {
            setIsLoading(false)
            setSucess(false)
            console.log(err.response.data)
            if(err.response.data.code == 3018){
                setErrorSaque('Saldo insuficiente.')
            } else if (err.response.data.code == 3155){
                setErrorSaque('Chave de PIX invalida.')
            } else if(err.response.data.code == 3004){
                setErrorSaque('Preencha todos os campos.')
            } else if (err.response.data.code == 3158){
                setErrorSaque('Valor mínimo para saque: R$50,00')
            }
            
        })
        
    }

    
  
    return (
      <div style={{width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Modal
          open={openSaque}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h2 style={{color: 'var(--Secondary-text)', fontSize: '32px'}}>Sacar</h2>

            <iframe style={{width: '100%', height: '100%'}} src={urlSacar}>

            </iframe>
            
          </Box>
        </Modal>
      </div>
    )
}

export default ModalSaque