import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { useControles } from "../../context/controles.context";
import SideBar from "../../components/SideBar";
import Header from "../../components/Header";
import ModalIframe from "../../components/ModalIframe";
import "../../index.css";
import { useGame } from "../../context/games.context";
import { useWhitelabel } from "../../context/whitelabel.context";
import LoadingMain from "../../components/Loadings/LoadingMain";
import { useAuth } from "../../context/auth.context";
import ModalMinhasApostas from "../../components/CardPerfil/ModalMinhasApostas";
import { GetSaldo, ListarDepositos, ListarSaques, MeuExtrato, VerificaToken } from "../../services/betfive";
import ModalMeusDepositos from "../../components/CardPerfil/ModalMeusDepositos";
import ModalMeusSaques from "../../components/CardPerfil/ModalMeusSaques";
import ModalDeposit from '../../components/Header/ModalDeposit'
import ModalSaque from "../../components/Header/ModalSaque";
import { MiniLoading } from "../../components/MiniLoading";

const ContainerPages = () => {
  const { isMobile, open, headerStatus, sideBarStatus, wizardAuxiliar, setWizardAuxiliar, openMinhasApostas, openMeusSaques, setopenMeusSaques, openMeusDepositos, setopenMeusDepositos, openDeposit, openSaque, loadingWithdraw, setloadingWithdraw, loadingDeposit, setloadingDeposit } = useControles();
  const { loadedWhitelabel, VerifySubDomain } = useWhitelabel();
  const { activeGame } = useGame();
  const [isLoading, setIsLoading] = useState(true);
  const {authStatus, GetBoolWizard, VerifAuth, setAuthStatus, newToken} = useAuth()
  
  
  const navigate = useNavigate()

  useEffect(() => {
    const loginTime = localStorage.getItem('horarioLogin');
      const currentTime = new Date().getTime();
      

      // console.log(currentTime - parseInt(loginTime), " => Tempo na sessao (expira em 3600000)")

      if (loginTime && currentTime - parseInt(loginTime) > 3600000) {
        localStorage.removeItem('@Email:BetBuilder')
        localStorage.removeItem('@Nome:BetBuilder')
        localStorage.removeItem('@Telefone:BetBuilder')
        sessionStorage.removeItem('@Token:BetBuilder')
        setAuthStatus(false)
        navigate(`/${localStorage.getItem('@Subdominio:BetBuilder')}`)

        localStorage.removeItem('@UserBetfive:Betbuilder')
        localStorage.removeItem('MobToken')
        localStorage.removeItem('saldo')
        localStorage.removeItem('tokenBetfive')
        localStorage.removeItem('horarioLogin')
      }
  }, [])

  useEffect(() => {
    // Simule um carregamento por 1 a 3 segundos
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, Math.floor(Math.random() * 2400) + 1000); // Entre 1000ms (1 segundo) e 4000ms (4 segundos)

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []); // Isso garante que o carregamento só acontecerá uma vez


  

  return (
    <>
      {!loadedWhitelabel || isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#000",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingMain />
        </div>
      ) : (
        <>
          {headerStatus && !window.location.pathname.includes('wizard') && <Header />}

          <Outlet />

          {isMobile && sideBarStatus &&  !window.location.pathname.includes('wizard') && <NavBar />}

          {open && <ModalIframe />}
          {openMinhasApostas && <ModalMinhasApostas/>}
          {openMeusDepositos && <ModalMeusDepositos/>}
          {openMeusSaques && <ModalMeusSaques/>}
          {openDeposit && <ModalDeposit/>}
          {openSaque && <ModalSaque/>}
          
          
        </>
      )}
    </>
  );
};

export default ContainerPages;
