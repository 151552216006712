import React, { useState, useEffect } from "react";
import { useWhitelabel } from "../../context/whitelabel.context";
import { Icon } from '@iconify/react';

const phrases = [
    "Carregando...",
    "Aguarde um momento...",
    "Estamos preparando tudo para você...",
    "Quase lá...",
];

const LoadingLogin = () => {
    const { logo } = useWhitelabel();
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 3000); // Alterar a frase a cada 3 segundos
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {/* <img src={logo} style={{width: '100px', height: '100px', objectFit: 'contain'}} className="loadingMain"/> */}
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Icon icon="mdi:cards-playing" className="loadingMain" style={{ color: 'var(--Primary-color)' }} />
                <div className="loadingPhrase">{phrases[currentPhraseIndex]}</div>
            </div>
            <style>
                {`
                    .loadingMain {
                        animation: jumpAndRotate 2s ease-in-out infinite;
                        width: 100px;
                        height: 100px;
                        object-fit: contain;
                    }

                    @keyframes jumpAndRotate {
                        0% {
                            transform: translateY(0) rotate(0deg);
                        }
                        20% {
                            transform: translateY(-20px) rotate(0deg);
                        }
                        50% {
                            transform: translateY(0) rotate(360deg);
                        }
                        100% {
                            transform: translateY(0) rotate(360deg);
                        }
                    }

                    .loadingPhrase {
                        margin-top: 20px;
                        font-size: 1.2em;
                        color: var(--Primary-color);
                        animation: fadeInOut 3s ease-in-out infinite;
                    }

                    @keyframes fadeInOut {
                        0%, 100% {
                            opacity: 0;
                        }
                        50% {
                            opacity: 1;
                        }
                    }
                `}
            </style>
        </>
    );
}

export default LoadingLogin;
