import React, { useEffect, useState } from "react";
import { useGame } from "../../context/games.context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, DivPai, ContainerInfo, DivContainerInfo,DivInfosEntrada, DivIframe } from "./styles";
import {useControles} from '../../context/controles.context';
import { BackgroundWrapper, PrimaryButton, PrimaryButtonNoRadius } from "../../globalComponents";
import { HackingLoading } from "../Loadings/HackingLoading";
import SepararDadosRegex from "../../utils/tratador";
import ModalTabela from "../ModalTabela";
import { useWhitelabel } from "../../context/whitelabel.context";
import axios from 'axios'
import { act } from "react";
import { sleep } from "../../utils/sleep";
import { socket } from "../../services/socketio";

const CardSalaDeJogos = () => {
    // const {roletaId, integracao, jogo} = useParams()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const roletaId = queryParams.get('roletaId');
    const integracao = queryParams.get('integracao');
    const jogo = queryParams.get('jogo');
    const listaSegundos = [3,4,5,6,7,8,9,10,11,12]
    const cincoMinutos = 300 //mudar pra 300

    const {setActiveGame, GetJogada, activeGame, GetJogadaTelegram, GetStatusResultadoByRoletaId} = useGame()
    const [linkPeloRequest, setLinkPeloRequest] = useState()

    const [ultimoStatusResultado, setUltimoStatusResultado] = useState()

    useEffect(function VerificaJogoAtivo(){
        const jogoAtivo = JSON.parse(localStorage.getItem('activeGame'))
        setActiveGame(jogoAtivo)

        console.log("Jogo Ativo: ", jogoAtivo)
    }, [])


    const retornarLinkCorreto = async () => {
        console.log(activeGame.fazerRequest, " => Fazer request")
        const token = JSON.parse(localStorage.getItem('loginObj')).results.token
        const url = activeGame.linkJogo
        try {
            if(activeGame.fazerRequest == 1) {
                const response = await axios.post(url.replace('${token}', token))                
                console.log(response, " => response do gameURL")
                setLinkPeloRequest(response.data.gameURL)
                return response.data.gameURL
            } else {
                return activeGame.linkJogo
            }
        } catch (err) {
            throw err
        }
    }

    const {verifySaldo, credits} = useWhitelabel()

    const {openDeposit, setopenDeposit} = useControles()
    
    const [entrada, setEntrada] = useState()
    const [entradaTelegram, setEntradaTelegram] = useState()

    const navigate = useNavigate()
    
    const { isMobile, setSideBarStatus } = useControles()
    const [cellScreen, setCellScreen] = useState()

    const [hackStatus, setHackStatus] = useState()
    const [analisandoStatus, setAnalisandoStatus] = useState(false)

    const [isGreen, setIsGreen] = useState(false)
    const [listaUltimos, setListaUltimos] = useState([])

    const [grafico, setGrafico] = useState()
    const [btOff, setBtOff] = useState(true)

    

    useEffect(() => {
        socket.disconnect()
        console.log("Conectando ao socket")
        socket.connect()

        socket.emit('game_update', roletaId)
    }, [])

    useEffect(() => {
        socket.on('game_update', (data) => {
            // console.log("Emitindo game_update: ", data.recordset[0])
            if(data.recordset[0].roletaId == roletaId){
                setActiveGame({
                    ...activeGame,
                    texto: data.recordset[0].texto,
                    statusResultado: data.recordset[0].statusResultado,
                    jogadaId: data.recordset[0].jogadaId,
                })
            }
        })
    }, [])


    const handleResize = () => {
        setCellScreen(window.innerWidth <= 770)
    }


    useEffect(() => {
        setSideBarStatus(false)
        if(!activeGame){
            navigate('../')
        }

        const linkCorreto = retornarLinkCorreto()
        if(linkCorreto && activeGame.fazerRequest == 1) {
            setActiveGame({
                ...activeGame,
                linkJogo: linkCorreto
            })
        }

        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
    }, [])


    const fetchData = async () => {
        const response = await GetJogada(roletaId)
        setEntrada(response.data[0])
        if(roletaId == 3){
            setGrafico(response.data[0].validade.replace(/🟦/g, '#').replace(/⭐️/g, '$').split(''))
        }
    }

    const fetchDataTelegram = async () => {
        
        const response = await GetJogadaTelegram(roletaId)
        const responseTratado = SepararDadosRegex(response.data[0])
        
        setEntradaTelegram(responseTratado)
        // console.log(responseTratado, " => response tratado")
        return responseTratado
    }

    const zerarHack = async () => {
        setHackStatus(false)
        setListaUltimos(null)
        setAnalisandoStatus(false)
        setActiveGame({
            ...activeGame,
            texto: null,
            jogadaId: null
        })
    }

    const handleHackear = async () => {
        setBtOff(false)
        setAnalisandoStatus(true)
        fetchData()
        
        const numeroEscolhidoIndex = Math.floor(Math.random() * listaSegundos.length);
        const numeroEscolhido = listaSegundos[numeroEscolhidoIndex];

    //    if (integracao == 1) {
    //         await fetchDataTelegram()
            

    //         const interval = setInterval(async ()=>{
    //             const response = await fetchDataTelegram()
                
                
    //             if(response.estado) {
    //                 if(response.estado != listaUltimos[listaUltimos.length - 1] && response.ativo == "1"){
    //                     const listaAuxiliar = listaUltimos
    //                     listaAuxiliar.push(response.estado)
                        
    //                     setListaUltimos(listaAuxiliar)
    //                     if(listaAuxiliar[listaAuxiliar.length - 1].includes("GREEN") || listaAuxiliar[listaAuxiliar.length - 1].includes("RED")){
    //                         const timeout = setTimeout(() => {
    //                             setIsGreen(true)
    //                         }, 8000)

    //                     } else {
    //                         setIsGreen(false)
    //                     }
    //                 }
    //             }
                
    //             if(response.ativo == "0"){
    //                 setHackStatus(false)    
    //             } else {
    //                 if(!isGreen){
    //                     setHackStatus(true)
    //                 }
                    
    //             }
    //         }, 4000)

    //         return () => clearInterval(interval)
    //     }
        // return () => clearTimeout(interval)
    }

    useEffect(() => {
        if(integracao == 1){
            // console.log('Verificando ultimo estado => ', listaUltimos[listaUltimos.length - 1])
    
            if (listaUltimos.length === 0 || listaUltimos[listaUltimos.length - 1].includes('GREEN') || listaUltimos[listaUltimos.length - 1].includes('RED')) {
                // console.log("Esperando próxima análise.")
        
                const time = setTimeout(() => {
                    console.log("Analisando...")
                    setHackStatus(false)
                    setAnalisandoStatus(true)
                    setIsGreen(true)
                }, 8000);
        
                
            } else {
                setIsGreen(false);
            }
        }
        
    }, [listaUltimos, isGreen]);
    
    

    useEffect(()=>{
       if(hackStatus){
            const interval = setInterval(()=>{
                setAnalisandoStatus(false)
                setHackStatus(false)
            }, 1000 * cincoMinutos)

            return () => clearInterval(interval)
       }
    }, [hackStatus])



    useEffect(() => {
        if(activeGame && integracao != '0'){
            const interval = setTimeout(()=>{
                console.log("Status: ", activeGame.statusResultado)
                fetchJogadaId()
            }, 3000)
            return () => clearTimeout(interval)
        }
    }, [activeGame])


    

    const fetchJogadaId = async () => {
        setBtOff(true)
        const response = await GetStatusResultadoByRoletaId(activeGame.roletaId)
        const resto = response.data[0]
        
        if(resto.statusResultado && (resto.statusResultado == 3 || resto.statusResultado == 4)){
            // console.log("Antes do setInterval")
            // console.log("StatusResultado: ", resto.statusResultado)
            setIsGreen(false)
            setUltimoStatusResultado(resto.statusResultado)
            setActiveGame({
                ...activeGame,
                statusResultado: resto.statusResultado,
            })
            // setAnalisandoStatus(false)
            const interval = setTimeout(async () => {
                // console.log("Analisando...")
                setActiveGame({
                    ...activeGame,
                    texto: null,
                    jogadaId: null,
                    ativo: resto.ativo,
                    // ...resto
                })
                
                await sleep(8000)
                setUltimoStatusResultado(resto.statusResultado)
                
                setAnalisandoStatus(true)
                setIsGreen(true)
                
            }, 8000)

            // setIsGreen(true)
            
            return () => clearTimeout(interval)
        } else {
            setActiveGame({
                ...activeGame,
                ...resto
            })
            setAnalisandoStatus(false)
            return
        }
    }

    useEffect(() => {
        if(isGreen){
            const timeout = setInterval(() => {
                setAnalisandoStatus(true)
            }, 8000)

            return () => clearInterval(timeout)
        }
    }, [isGreen])

    const handleDeposit = () => {
        setopenDeposit(true)
    }


    if(verifySaldo == 1 && credits == '0') {
        
        return (
            <Container style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh'}}>
                <div style={{border: '2px solid var(--Primary-color)', width: '400px', maxWidth: '300px', height: '350px', maxHeight: '350px', borderRadius: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '64px'}}>
                    <h1 style={{color: 'white', textAlign: 'center', width: '100%'}}>
                        Usuário sem saldo.
                    </h1>
                    <PrimaryButton style={{height: 40, padding: 15}}
                        onClick={()=>{
                            handleDeposit()
                        }}>
                            Deposite e Jogue
                    </PrimaryButton>
                </div>
            </Container>
        )
    }

    return (
        <>
        <Container>

        {(!hackStatus || (integracao == 1)) && ((!activeGame.jogadaId && !activeGame.texto) || integracao == '0' || activeGame.ativo == 0) ? 
        
        <>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh'}}>
                {!analisandoStatus && activeGame.ativo != 0 ? 
                    <PrimaryButton onClick={handleHackear} style={{width: '250px', height: '250px', borderRadius: '300px', fontSize: '28px', boxShadow: '0px 0px 5px 2px var(--Primary-color)', fontWeight: '600'}}>Hackear Sinais</PrimaryButton>
                    :
                    <>
                        <HackingLoading/>
                    </>
                }
            
            </div>
        </> 
        
        
        : 
        
        <>
            <DivPai>
                <div 
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '1024px', width: '100%', height: '100%', padding: '10px 10px 20px 10px', borderRadius: '16px', gap: 20}}
                >
                {/* <ModalTabela/> */}
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: !cellScreen && 'space-between', alignItems: 'center', width: '100%', height: 'auto', gap: isMobile && 10}}>
                    {!cellScreen && activeGame && 
                        <img src={activeGame.urlIcone} style={{maxWidth: '150px', borderRadius: '16px', height: '150px'}}/>
                    }
                    
                    {!activeGame.texto && !activeGame.jogadaId && integracao == '0' ? 
                        <DivInfosEntrada>
                            
                                <DivContainerInfo>
                                    <label>{entrada ? entrada.inputValue : "Estratégia"}</label>
                                    <ContainerInfo>
                                        {entradaTelegram && entradaTelegram.estrategia ? entradaTelegram.estrategia : entrada.entrada}
                                    </ContainerInfo>
                                </DivContainerInfo>
                            
                            

                        {entrada && !entrada.tentativas.includes("Null")  && 
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue2 : "Tentativas"}</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {jogo == "OFFLINE" ? entrada.tentativas : jogo == "ROLETA" ? "Cobrir 0": jogo == "CARDS" ? "Cobrir empate" : jogo == "CRASH" ? "3x" : "Cobrir Any Triple"}
                                </ContainerInfo>
                            </DivContainerInfo>
                        }
                        
                        {entrada && !entrada.validade.includes("Null") && roletaId != 3 && 
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue3 : "Dica"}</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {entrada.validade}
                                    
                                </ContainerInfo>
                            </DivContainerInfo>
                        }
                        {/* {entradaTelegram && integracao == '1' && 
                            <DivContainerInfo>
                                <label>Ação</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {entradaTelegram.estado}
                                </ContainerInfo>
                            </DivContainerInfo>
                        } */}
                        
                        
                    </DivInfosEntrada>
                    :
                    <DivInfosEntrada>
                            
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue : activeGame.statusResultado <= 2 || integracao == '0' ? "Estratégia" : "Resultado"}</label>
                                <ContainerInfo style={{width: activeGame.statusResultado <= 2 ? '100%' : '100%'}}>
                                    {activeGame.statusResultado == 3 || activeGame.texto.includes("GREEN") && integracao == '1' ? "🟢 🟢 GREEN 🟢 🟢" :
                                    activeGame.statusResultado == 4 || activeGame.texto.includes("RED") && integracao == '1' ? "🔴 🔴 RED 🔴 🔴" : 
                                    activeGame.texto.includes("Analisando") && integracao == '1' ? activeGame.texto : activeGame.texto} 
                                </ContainerInfo>
                            </DivContainerInfo>
                            
                            

                            

                        {activeGame.texto && activeGame.statusResultado <= 2 && activeGame.ativo == 1 && 
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue2 : "Tentativas"}</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '200px'}}>
                                    {/* {jogo == "OFFLINE" ? entrada.tentativas : jogo == "ROLETA" ? "Cobrir 0": jogo == "CARDS" ? "Cobrir empate" : jogo == "CRASH" ? "3x" : "Cobrir Any Triple"} */}
                                    {/* {activeGame.texto} */}
                                    {/* {activeGame.statusResultado == 1 ? formatterAnalisando(activeGame.texto).padrao : formatterConfirmada(activeGame.texto).padrao}  */}
                                    3x
                                </ContainerInfo>
                            </DivContainerInfo>
                        }
                        
                        {activeGame.texto && activeGame.statusResultado <= 2 && jogo != "CRASH" && activeGame.ativo == 1 && 
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue3 : "Dica"}</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {jogo == "OFFLINE" ? entrada.tentativas : jogo == "ROLETA" ? "Cobrir 0": jogo == "CARDS" ? "Cobrir empate" : jogo == "CRASH" ? "3x" : "Cobrir Any Triple"}
                                </ContainerInfo>
                            </DivContainerInfo>
                        }
                        {entradaTelegram && integracao == '1' && 
                            <DivContainerInfo>
                                <label>Ação</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {entradaTelegram.estado}
                                </ContainerInfo>
                            </DivContainerInfo>
                        }

                        {activeGame.statusResultado <= 2 && integracao == '1' && 
                                <DivContainerInfo>
                                    <label>Status</label>
                                    <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                        {activeGame.ativo == 0 ? 'Hackeando mesa' :
                                        activeGame.statusResultado == 1 ? 'Analisando' :
                                        activeGame.statusResultado == 2 && 'Entrada Confirmada'}
                                    </ContainerInfo>
                                </DivContainerInfo>
                        }
                        
                        
                    </DivInfosEntrada>
                }
                   

                    {roletaId == 3 && 
                    <div style={{display: 'flex', width: isMobile ? '150px' : '200px', height: isMobile ? '120px' : '150px', alignItems: 'center', justifyContent: 'center', background: 'var(--Toggle-color)', borderRadius: '8px', padding: '10px', marginTop: isMobile && 10}}>
                        <div style={{width: '100%',height: '100%', zIndex: 999, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', alignItems: 'center', justifyContent: 'center', gap: 2, paddingLeft: 5}}>
                            {entrada && grafico.map((i, indice) => {
                                
                                if(indice < 25){
                                    return (
                                        <span style={{fontSize: isMobile ? '12px' : '16px'}}>{i == '#' ? '🟦' : '⭐️'}</span>
                                    )
                                }
                            })}
                            
                        </div>
                    </div>
                    }
                </div>
                </div>
            </DivPai>
        </>}
        <DivIframe style={{
            display: (!hackStatus || (integracao == 1)) && ((!activeGame.jogadaId && !activeGame.texto) || integracao == '0' || activeGame.ativo == 0) ? 'none' : 'block',
            maxWidth: '1024px'
        }}>
            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <iframe src={
                    activeGame && activeGame.linkJogo &&  activeGame.fazerRequest != 1 ?
                    activeGame.linkJogo.replace('{token}', JSON.parse(localStorage.getItem('loginObj')).results.tokenCassino)
                    .replace('{mobToken}', encodeURI(localStorage.getItem('MobToken'))) :
                    linkPeloRequest
                } 
                
                style={{width: '100%', height: '100%', minHeight: '500px', border: '1px solid var(--Primary-color)', borderRadius: '8px'}}>
                </iframe>
                
            </div>
            {!btOff && 
                <PrimaryButtonNoRadius style={{width: '100%', marginTop: '18px'}} onClick={() => {
                    zerarHack()
                    handleHackear()
                }}>Hackear novamente</PrimaryButtonNoRadius>
            }
        </DivIframe>
        </Container>

        <BackgroundWrapper>
            <div className="bgImage">
            </div>
        </BackgroundWrapper>
        </>
    )
}

export default CardSalaDeJogos