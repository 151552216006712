import axios from "axios";
 
const playgreen = axios.create({
  baseURL: "https://apiplaygreen.smartsig.com.br",
  headers: {
    Authorization: ""
  }
});


export default playgreen;


export const LoginPlaygreen = async (user, url) => {
  try {
    const response = await axios.post(url, {
        username: user.username,
        password: user.password,
        recaptchaToken: ""
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}






